import router from "../router";
import store from "../store";

import {
  createAcl,
  defineAclRules
} from "vue-simple-acl";
import {
  GET_USER
} from "@/store/getters";
import {
  STORAGE
} from "@/services";
import {
  computed
} from "vue";

const user = computed(() => store.state.auth.user);

const rules = () =>
  defineAclRules((setRule) => {
    setRule("dashboard", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "dashboard")
    );

    setRule("packages", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "packages")
    );
    setRule("add-package", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-package"
      )
    );
    setRule("delete-package", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-package"
      )
    );
    setRule("update-package", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-package"
      )
    );

    setRule("companies", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "companies")
    );
    setRule("add-company", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-company"
      )
    );
    setRule("delete-company", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-company"
      )
    );
    setRule("update-company", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-company"
      )
    );

    setRule("security-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "security-group"
      )
    );
    setRule("add-security-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-security-group"
      )
    );
    setRule("delete-security-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-security-group"
      )
    );
    setRule("update-security-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-security-group"
      )
    );

    setRule("configuration", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "configuration"
      )
    );

    setRule("department", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "department"
      )
    );
    setRule("add-department", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-department"
      )
    );
    setRule("delete-department", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-department"
      )
    );
    setRule("update-department", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-department"
      )
    );

    setRule("city", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "city")
    );
    setRule("add-city", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "add-city")
    );
    setRule("delete-city", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-city"
      )
    );
    setRule("update-city", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-city"
      )
    );

    setRule("city-area", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "city-area")
    );
    setRule("add-city-area", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-city-area"
      )
    );
    setRule("delete-city-area", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-city-area"
      )
    );
    setRule("update-city-area", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-city-area"
      )
    );

    setRule("item", (user) =>
      user?.data?.permissions?.data?.some?.((item) => item.name === "item")
    );

    setRule("item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-definition"
      )
    );
    setRule("add-item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-definition"
      )
    );
    setRule("delete-item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-definition"
      )
    );
    setRule("update-item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-definition"
      )
    );
    setRule("print-item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "print-item-definition"
      )
    );
    setRule("csv-item-definition", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "csv-item-definition"
      )
    );

    setRule("item-code-template", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-code-template"
      )
    );
    setRule("add-item-code-template", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-code-template"
      )
    );
    setRule("delete-item-code-template", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-code-template"
      )
    );
    setRule("update-item-code-template", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-code-template"
      )
    );

    
    setRule("item-size", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-size"
      )
    );

    setRule("add-item-size", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-size"
      )
    );
    setRule("delete-item-size", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-size"
      )
    );
    setRule("update-item-size", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-size"
      )
    );

    setRule("item-combination", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-combination"
      )
    );
    setRule("add-item-combination", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-combination"
      )
    );
    setRule("delete-item-combination", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-combination"
      )
    );
    setRule("update-item-combination", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-combination"
      )
    );

    setRule("department-based-attribute", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "department-based-attribute"
      )
    );

    setRule("view-item-group-list", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "view-item-group-list"
      )
    );
    setRule("add-item-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-group"
      )
    );
    setRule("delete-item-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-group"
      )
    );
    setRule("update-item-group", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-group"
      )
    );

    setRule("item-category", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-category"
      )
    );
    setRule("add-item-category", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-category"
      )
    );
    setRule("delete-item-category", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-category"
      )
    );
    setRule("update-item-category", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-category"
      )
    );

    setRule("item-subcategory", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-subcategory"
      )
    );
    setRule("add-item-subcategory", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-subcategory"
      )
    );
    setRule("delete-item-subcategory", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-subcategory"
      )
    );
    setRule("update-item-subcategory", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-subcategory"
      )
    );

    setRule("item-db-atttribute-one", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-db-atttribute-one"
      )
    );
    setRule("add-item-db-atttribute-one", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-db-atttribute-one"
      )
    );
    setRule("delete-item-db-atttribute-one", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-db-atttribute-one"
      )
    );
    setRule("update-item-db-atttribute-one", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-db-atttribute-one"
      )
    );

    setRule("item-db-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-db-atttribute-two"
      )
    );
    setRule("add-item-db-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-db-atttribute-two"
      )
    );
    setRule("delete-item-db-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-db-atttribute-two"
      )
    );
    setRule("update-item-db-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-db-atttribute-two"
      )
    );

    setRule("item-based-attribute", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-based-attribute"
      )
    );

    setRule("view-calendar-list", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "view-calendar-list"
      )
    );
    setRule("add-calendar", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-calendar"
      )
    );
    setRule("delete-calendar", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-calendar"
      )
    );
    setRule("update-calendar", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-calendar"
      )
    );

    setRule("item-gender", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-gender"
      )
    );
    setRule("add-item-gender", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-gender"
      )
    );
    setRule("delete-item-gender", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-gender"
      )
    );
    setRule("update-item-gender", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-gender"
      )
    );

    setRule("item-brand", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-brand"
      )
    );
    setRule("add-item-brand", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-brand"
      )
    );
    setRule("delete-item-brand", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-brand"
      )
    );
    setRule("update-item-brand", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-brand"
      )
    );

    setRule("item-item-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-item-atttribute-two"
      )
    );
    setRule("add-item-item-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-item-atttribute-two"
      )
    );
    setRule("delete-item-item-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-item-atttribute-two"
      )
    );
    setRule("update-item-item-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-item-atttribute-two"
      )
    );

    setRule("item-atttribute-five", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-atttribute-five"
      )
    );
    setRule("add-item-atttribute-five", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-atttribute-five"
      )
    );

    setRule("add-item-atttribute-two", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-atttribute-two"
      )
    );

    

    setRule("delete-item-atttribute-five", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-atttribute-five"
      )
    );
    setRule("update-item-atttribute-five", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-atttribute-five"
      )
    );

    setRule("item-atttribute-six", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-atttribute-six"
      )
    );
    setRule("add-item-atttribute-six", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-atttribute-six"
      )
    );
    setRule("delete-item-atttribute-six", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-atttribute-six"
      )
    );
    setRule("update-item-atttribute-six", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-atttribute-six"
      )
    );

    setRule("item-atttribute-seven", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "item-atttribute-seven"
      )
    );
    setRule("add-item-atttribute-seven", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "add-item-atttribute-seven"
      )
    );
    setRule("delete-item-atttribute-seven", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "delete-item-atttribute-seven"
      )
    );
    setRule("update-item-atttribute-seven", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "update-item-atttribute-seven"
      )
    );

    setRule("change-password", (user) =>
      user?.data?.permissions?.data?.some?.(
        (item) => item.name === "change-password"
      )
    );

    setRule('security-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'security-user'))
    setRule('add-security-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-security-user'))
    setRule('view-security-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-security-user'))
    setRule('edit-security-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-security-user'))
    setRule('delete-security-user',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-security-user'))

    setRule('security-group',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'security-group'))
    setRule('add-security-group',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-security-group'))
    setRule('view-security-group',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-security-group'))
    setRule('edit-security-group',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-security-group'))
    setRule('delete-security-group',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-security-group'))


    setRule('group-right',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'group-right'))
    setRule('add-group-right',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'add-group-right'))
    setRule('view-group-right',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'view-group-right'))
    setRule('edit-group-right',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'edit-group-right'))
    setRule('delete-group-right',(user)=>user?.data?.permissions?.data?.some?.(item => item.name === 'delete-group-right'))


  });

const simpleAcl = createAcl({
  user,
  rules,
  router,
});

export default simpleAcl;